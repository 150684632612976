<template>
  <div class="modal-top modal-grid fade">
    <div class="modal-left">
      <div
        class="modal-poster"
        :style="'background: url(' + posterMini + '); background-size:cover'"
      >
        <img :src="urlPoster" :alt="asset.title" v-on:error="posterLoadError" />
      </div>
    </div>

    <div class="modal-right">
      <div class="modal-header">
        <template v-if="asset.serie_name && asset.serie_name != asset.title">
          <h5 class="modal-title" :title="asset.serie_name">
            {{ asset.serie_name }}
          </h5>
          <h6 class="modal-subtitle" :title="asset.title">
            {{ textoCortoTemporadaEpisodio }} - {{ asset.title }}
          </h6>
        </template>
        <template v-else>
          <h5 class="modal-title" :title="asset.title">{{ asset.title }}</h5>
        </template>

        <div class="modal-metadata">
          <!--solucion temporal de ids 18 y 20 rotos hasta que lo corrijan en el origen issue #5041-->
          <span
            class="channel-logo"
            v-if="asset.channel.id != 18 && asset.channel.id != 20"
          >
            <img
              v-if="asset.channel && asset.channel.logo"
              :src="asset.channel.logo"
              class="img-logo"
              :alt="asset.channel.id"
            />
          </span>
          <i v-if="asset.certificate && asset.certificateCode" class="zmdi zmdi-circle"></i>
          <span
            v-if="asset.certificate && asset.certificateCode"
            class="certificate-code"
            :title="'Calificación: ' + asset.certificate"
            >{{ asset.certificateCode }}</span
          >
          <i v-if="year" class="zmdi zmdi-circle"></i>
          <span v-if="year" class="year">{{ year }}</span>
          <i v-if="asset.duration" class="zmdi zmdi-circle"></i>
          <span v-if="asset.duration || seasonsAndChapters" class="total-time"
            >{{ asset.duration }} min.</span
          >
          <span v-if="seasonsAndChapters" class="seasons-and-chapters">{{
            seasonsAndChapters.toLowerCase()
          }}</span>
          <i v-if="genres" class="zmdi zmdi-circle"></i>
          <div v-if="genres" class="genres">
            <template v-for="(genre, i) in genres">
              <span>{{ genre }}</span
              >{{ i === genres.length - 1 ? "" : ", "
              }}<!--
						--></template
            >
          </div>
          <i v-if="typeof asset.rating !== 'undefined'" class="zmdi zmdi-circle"></i>
          <div v-if="typeof asset.rating !== 'undefined'" class="modal-rating">
            <div class="rating" :data-rating="asset.rating">
              <i class="zmdi zmdi-star-outline zmdi-star-1"></i>
            </div>

            <span class="rating-total">
              {{ asset.rating }}
              <!-- <img src="../assets/images/tplay-iso.svg" width="25" height="25" alt="TPlay ISO"> -->
            </span>
          </div>
        </div>

        <!-- <div v-if="typeof asset.rating !== 'undefined'" class="modal-rating">

					<div class="rating" :data-rating="asset.rating">
						<i class="zmdi zmdi-star-outline zmdi-star-1"></i>
						<i class="zmdi zmdi-star-outline zmdi-star-2"></i>
						<i class="zmdi zmdi-star-outline zmdi-star-3"></i>
						<i class="zmdi zmdi-star-outline zmdi-star-4"></i>
						<i class="zmdi zmdi-star-outline zmdi-star-5"></i>
					</div>

					<span class="rating-total">
						({{ asset.rating }})
						<img src="../assets/images/tplay-iso.svg" width="25" height="25" alt="TPlay ISO">
					</span>
				</div> -->
      </div>

      <div class="modal-body">
        <div
          class="modal-detail"
          :class="{
            'modal-detail--chapter':
              asset.serie_name && asset.serie_name != asset.title,
          }"
        >
          <p class="sinopsis">{{ asset.synopsis }}</p>

          <p class="extra-data">
            <span v-if="asset.director"
              ><strong>Director:</strong> {{ asset.director }} <br
            /></span>
            <span v-if="asset.actors"
              ><strong>Actores:</strong> {{ asset.actors }}</span
            >
          </p>
        </div>

        <span class="shadow-fade"></span>

        <div class="modal-actions">
          <a
            v-if="$listeners.play"
            v-on:click.prevent="onClick('play')"
            href="#"
            class="btn btn-primary"
            :title="textoLargoReproducir"
          >
            <i class="zmdi zmdi-play-circle-outline"></i>
            <span>{{ textoCortoReproducir }}</span>
          </a>

          <a
            v-if="$listeners.favorite"
            v-on:click.prevent="$emit('favorite')"
            href="#"
            class="btn btn-secondary btn-favorite"
            :class="{ favorited: asset.favorite }"
            :title="
              asset.favorite
                ? 'Quitar de mis favoritos'
                : 'Agregar a mis favoritos'
            "
          >
            <i
              class="zmdi zmdi-favorite-outline"
              :class="{ 'zmdi-favorite': asset.favorite }"
            ></i>
            <span class="sr-only">Favorito</span>
          </a>

          <div v-if="$listeners.score" class="popover-wrapper">
            <a href="#" class="popover-trigger" title="Calificar este título">
              <i class="zmdi zmdi-star-outline"></i>
              <span>Calificar Título</span>
            </a>

            <div class="popover-content">
              <span class="popover-title">¿Cuántas estrellas le darías?</span>

              <form role="form" novalidate>
                <fieldset class="rating-interaction">
                  <template v-for="r in rating">
                    <input
                      type="radio"
                      :id="'star' + r.score + '-serie'"
                      name="rating"
                      :value="r.score"
                      :checked="r.score == asset.userRating"
                    />
                    <label
                      v-on:click="$emit('score', r.score)"
                      :for="'star' + r.score + '-serie'"
                      :title="r.desc"
                    >
                      <span>{{ r.desc }}</span>
                    </label>
                  </template>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const IMG_DEFAULT_ALPHA =
  "a5434fa604af965aa91eb3e32c523ae2f383bfdf6f6cbaa5682ed36a60b5b33e";

export default {
  name: "ModalHeaderVod",

  props: {
    asset: {
      type: Object,
      required: true,
    },
    poster: {
      type: String,
      required: false,
    },
    posterMini: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      rating: tplay.rating(),
    };
  },

  computed: {
    urlPoster() {
      const urlFromAlpha = this.poster.split("/").pop();

      if (urlFromAlpha === IMG_DEFAULT_ALPHA) {
        return window.location.origin + "/assets/images/no-poster-5-7.png";
      } else {
        return this.poster
          ? this.poster
          : window.location.origin + "/assets/images/no-poster-5-7.png";
      }
    },

    seasonsAndChapters() {
      if (this.asset.type !== "CHAPTER" && this.asset.type !== "SERIE") {
        return null;
      } else if (!this.asset.seasons || this.asset.seasons.length === 0) {
        return null;
      } else if (this.asset.seasons.length === 1) {
        return this.asset.seasons.length + " Temporada";
      } else {
        return this.asset.seasons.length + " Temporadas";
      }
    },

    primerCapituloConDatos() {
      let capitulo = null;

      if (this.asset && this.asset.seasons) {
        //Busco el primer episodio de la primera temporada que tenga episodios cargados
        capitulo = this.asset.seasons.find(
          (season) => season.capitulos && season.capitulos[0]
        ).capitulos[0];
      } else {
        capitulo = this.asset;
      }

      return capitulo;
    },

    primerCapitulo() {
      if (
        this.asset.seasons &&
        this.asset.seasons[0] &&
        this.asset.seasons[0].capitulos &&
        this.asset.seasons[0].capitulos[0]
      ) {
        return this.asset.seasons[0].capitulos[0];
      }

      return null;
    },

    genres() {
      let genres = null;

      if (this.asset && this.asset.genres) {
        genres = this.asset.genres;
      } else if (this.primerCapituloConDatos) {
        genres = this.primerCapituloConDatos.genres;
      }

      return genres;
    },

    year() {
      let year = null;

      if (this.asset && this.asset.year) {
        year = this.asset.year;
      } else if (this.primerCapitulo) {
        year = this.primerCapitulo.year;
      }

      return year;
    },

    textoCortoTemporadaEpisodio() {
      if (this.asset.type === "CHAPTER") {
        return [
          this.asset.n_temporada ? "T" + this.asset.n_temporada : null,
          this.asset.n_episodio ? "E" + this.asset.n_episodio : null,
        ]
          .filter((x) => x)
          .join(":");
      }

      return "";
    },

    textoCortoReproducir() {
      return "Reproducir " + this.textoCortoTemporadaEpisodio;
    },

    textoLargoReproducir() {
      if (this.asset.type === "CHAPTER") {
        return (
          "Reproducir Temporada " +
          this.asset.n_temporada +
          ": Episodio " +
          this.asset.n_episodio
        );
      }

      return "Reproducir";
    },
  },

  mounted() {
    const modalTop = this.$el;

    //Los 100ms son para que se aplique la transición de .fade correctamente
    setTimeout(function () {
      modalTop.classList.add("show");
    }, 100);
  },

  methods: {
    posterLoadError(event) {
      event.target.src = "../assets/images/no-poster-5-7.png";
    },

    onClick(state) {
      let self = this;
      let blockByParentalControl = null;

      if (self.primerCapituloConDatos) {
        blockByParentalControl =
          telecentro.tplay.core.biz.parentalControl.blockAsset(
            self.primerCapituloConDatos
          );
      }
      if (blockByParentalControl) {
        this.$bus.$emit("modal-pin:show", function () {
          self.$emit(state);
        });
      } else {
        self.$emit(state);
      }
      let butttonClose = document.querySelector(".close");
      butttonClose?.click();
    },
  },
};
</script>
